// Core packages
import React from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import watchvideo_it from "../assets/watch-video_it.png";
import accessfee_it from "../assets/access-fee_it.png";
import watchvideo_en from "../assets/watch-video_en.png";
import accessfee_en from "../assets/access-fee_en.png";

/**
 * Script start
 */
const Biglietto = (props) => {
  const { curr_lang } = props;

  return (
    <Container fluid className="biglietto">
      <Row style={{ marginTop: "90px", marginBottom: "90px" }}>
        <Col md={6} style={{ textAlign: "right" }}>
          <a href="https://www.youtube.com/watch?v=jNfyafB0Pb8" target="_blank">
            <img
              src={curr_lang === "it" ? watchvideo_it : watchvideo_en}
              alt="Watch Corenno Plinio Promo Video"
              style={{ maxWidth: "575px", width: "100%" }}
            />
          </a>
        </Col>
        <Col md={6} style={{ textAlign: "left" }}>
          <a href={`/${curr_lang}/info`}>
            <img
              src={curr_lang === "it" ? accessfee_it : accessfee_en}
              alt="Biglietti Corenno Pilinio"
              style={{ maxWidth: "575px", width: "100%" }}
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Biglietto);
